<template>
  <div>
    <div class="header-block mb-4">
      <h4 class="font-weight-bold mb-0">
        <i
          v-if="titleIcon"
          :class="['ion',`ion-${titleIcon}`]"
        />
        {{ title }}
      </h4>
      <slot>
        <div v-if="buttonTitle && showButton">
          <span
            v-b-tooltip.hover.left
            :title="tooltipText"
            :disabled="!isButtonDisabled"
          >
            <b-button
              :disabled="isButtonDisabled"
              variant="info"
              @click="$emit('onCreateElem')"
            >
              <i
                v-if="buttonIcon"
                :class="['ion',`ion-${buttonIcon}`]"
              />
              {{ buttonTitle }}
            </b-button>
          </span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Head',
  props: {
    titleIcon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: 'Button is disabled',
    },
  },
};
</script>

<style scoped lang="scss">
  .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }
</style>
