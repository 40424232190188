<template>
  <cp-general-modal
    ref="issuerBank"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('issuerBankAccounts.bankModal.input.name')"
    />
    <cp-textarea
      v-model="model.instructions"
      name="instructions"
      :label="$t('issuerBankAccounts.bankModal.input.instructions')"
      :rows="5"
    />
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'IssuerBankModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpTextarea,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
    };
  },

  computed: {
    getTitleName() {
      return this.$t('issuerBankAccounts.bankModal.title', [this.titleData.label]);
    },
  },

  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.titleData.type === 'Edit' ? 'update' : 'create';
      this.$emit('onSubmit', { action, bank: this.model });
    },
  },
};
</script>
